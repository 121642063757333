import React, { useState } from "react"

import noimage from "../../images/commercial/no-image.jpg"

import img1 from "../../images/commercial/photo_pereg.jpeg"
import img2 from "../../images/commercial/photo_potol.jpeg"
import img3 from "../../images/commercial/photo_sten.jpeg"
import img4 from "../../images/commercial/panels/0.jpeg"
import img6 from "../../images/commercial/photo_kor.jpeg"
import img7 from "../../images/commercial/photo-delivery.jpg"
import img8 from "../../images/commercial/photo_montaj.jpg"
import img9 from "../../images/commercial/photo_krep.jpg"

import panel0 from "../../images/commercial/panels/0.jpeg"
import panel1 from "../../images/commercial/panels/1.jpeg"
import panel2 from "../../images/commercial/panels/2.jpeg"
import panel3 from "../../images/commercial/panels/3.jpeg"
import panel4 from "../../images/commercial/panels/4.jpeg"
import panel5 from "../../images/commercial/panels/5.jpeg"
import panel6 from "../../images/commercial/panels/6.jpeg"
import panel7 from "../../images/commercial/panels/7.jpeg"
import panel8 from "../../images/commercial/panels/8.jpeg"
import panel9 from "../../images/commercial/panels/9.jpeg"
import panel10 from "../../images/commercial/panels/10.jpeg"
import panel11 from "../../images/commercial/panels/11.jpeg"
import panel12 from "../../images/commercial/panels/12.jpeg"
import panel13 from "../../images/commercial/panels/13.jpeg"
import panel14 from "../../images/commercial/panels/14.jpeg"
import panel15 from "../../images/commercial/panels/15.jpeg"
import panel16 from "../../images/commercial/panels/16.jpeg"
import panel17 from "../../images/commercial/panels/17.jpeg"
import panel18 from "../../images/commercial/panels/18.jpeg"
import panel19 from "../../images/commercial/panels/19.jpeg"
import panel20 from "../../images/commercial/panels/20.jpeg"
import panel21 from "../../images/commercial/panels/21.jpeg"
import panel22 from "../../images/commercial/panels/22.jpeg"
import panel23 from "../../images/commercial/panels/23.jpeg"
import panel24 from "../../images/commercial/panels/24.jpeg"
import panel25 from "../../images/commercial/panels/25.jpeg"
import panel26 from "../../images/commercial/panels/26.jpeg"
import panel27 from "../../images/commercial/panels/27.jpeg"
import panel28 from "../../images/commercial/panels/28.jpeg"
import panel29 from "../../images/commercial/panels/29.jpeg"
import panel30 from "../../images/commercial/panels/30.jpeg"
import panel31 from "../../images/commercial/panels/31.jpeg"
import panel32 from "../../images/commercial/panels/32.jpeg"

import { Popover, PopoverContent, PopoverTrigger } from "../UI/popover"
import { Button } from "../UI/button"
import { PanelsSlider } from "./PanelsSlider"

const ToDoForm = ({ addTask }) => {
  const data = [
    { id: 0, name: "Перегородки", image: img1 },
    { id: 1, name: "Потолочные рейки", image: img2 },
    { id: 2, name: "Стеновые рейки", image: img3 },
    { id: 3, name: "Стеновые панели", image: img4 },
    { id: 4, name: "Рейка короб", image: img6 },
    { id: 5, name: "Крепления", image: img9 },
    { id: 6, name: "Доставка", image: img7 },
    { id: 7, name: "Монтаж", image: img8 },
  ]

  const panels = [
    { id: 0, name: "Стеновая панель не выбрана", image: noimage },
    { id: 1, name: "Стеновая панель 0", image: panel0 },
    { id: 2, name: "Стеновая панель 1", image: panel1 },
    { id: 3, name: "Стеновая панель 2", image: panel2 },
    { id: 4, name: "Стеновая панель 3", image: panel3 },
    { id: 5, name: "Стеновая панель 4", image: panel4 },
    { id: 6, name: "Стеновая панель 5", image: panel5 },
    { id: 7, name: "Стеновая панель 6", image: panel6 },
    { id: 8, name: "Стеновая панель 7", image: panel7 },
    { id: 9, name: "Стеновая панель 8", image: panel8 },
    { id: 10, name: "Стеновая панель 9", image: panel9 },
    { id: 11, name: "Стеновая панель 10", image: panel10 },
    { id: 12, name: "Стеновая панель 11", image: panel11 },
    { id: 13, name: "Стеновая панель 12", image: panel12 },
    { id: 14, name: "Стеновая панель 13", image: panel13 },
    { id: 15, name: "Стеновая панель 14", image: panel14 },
    { id: 16, name: "Стеновая панель 15", image: panel15 },
    { id: 17, name: "Стеновая панель 16", image: panel16 },
    { id: 18, name: "Стеновая панель 17", image: panel17 },
    { id: 19, name: "Стеновая панель 18", image: panel18 },
    { id: 20, name: "Стеновая панель 19", image: panel19 },
    { id: 21, name: "Стеновая панель 20", image: panel20 },
    { id: 22, name: "Стеновая панель 21", image: panel21 },
    { id: 23, name: "Стеновая панель 22", image: panel22 },
    { id: 24, name: "Стеновая панель 23", image: panel23 },
    { id: 25, name: "Стеновая панель 24", image: panel24 },
    { id: 26, name: "Стеновая панель 25", image: panel25 },
    { id: 27, name: "Стеновая панель 26", image: panel26 },
    { id: 28, name: "Стеновая панель 27", image: panel27 },
    { id: 29, name: "Стеновая панель 28", image: panel28 },
    { id: 30, name: "Стеновая панель 29", image: panel29 },
    { id: 31, name: "Стеновая панель 30", image: panel30 },
    { id: 32, name: "Стеновая панель 31", image: panel31 },
    { id: 33, name: "Стеновая панель 32", image: panel32 },
  ]

  const values = [
    { id: 0, name: "шт." },
    { id: 1, name: "м²" },
  ]
  const [selected, setSelected] = useState(data[0].id)
  const [selectedPanel, setSelectedPanel] = useState(panels[0].id)

  const [selectedValue, setSelectedValue] = useState(values[0].id)

  const [price, setPrice] = useState("")
  const [quantity, setQuantity] = useState("")
  const [userInput, setUserInput] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    addTask({
      selectedId:
        selected === "3" ? panels[selectedPanel].image : data[selected].image,
      selectedName: data[selected].name,
      userInput,
      price,
      quantity,
      selectedValue: values[selectedValue].name,
      sum: price * quantity,
    })

    setSelected(data[0].id)
    setSelectedPanel(panels[0].id)
    setUserInput("")
    setPrice("")
    setQuantity("")
  }

  return (
    <div className="pb-2">
      <h2 className="py-1 text-base">Добавление записи</h2>
      <div className="grid grid-cols-7 gap-1 border-t border-gray-300 pt-1 text-sm">
        <div>Изображение</div>
        <div className="col-span-2">Наименование</div>
        <div>Количество</div>
        <div>Цена</div>
        <div>Сумма</div>
        <div />

        <div className="space-y-1">
          {selected === "3" ? (
            <Popover>
              <PopoverTrigger
                asChild
                className={selected === "3" ? "block" : "hidden"}
              >
                {selectedPanel ? (
                  <img
                    src={panels[selectedPanel].image}
                    className="h-28 cursor-pointer"
                    alt={panels[selectedPanel]?.name}
                  />
                ) : (
                  <div className="h-24 w-24 cursor-pointer rounded-md border border-gray-300  bg-white p-2 text-center text-gray-700 shadow-inner hover:border-gray-500 hover:text-gray-900">
                    <div className="text-sm">Выбрать панель</div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="inline-flex h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                      />
                    </svg>
                  </div>
                )}
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <PanelsSlider panels={panels} setSelected={setSelectedPanel} />
              </PopoverContent>
            </Popover>
          ) : (
            <img
              src={data[selected].image}
              className="h-28"
              alt={data[selected].name}
            />
          )}

          <select
            name="images"
            id="images-select"
            value={selected}
            onChange={e => setSelected(e.target.value)}
            className="w-36 rounded-lg border border-gray-300 p-1 text-xs"
          >
            {data.map(record => (
              <option key={`image-${record.id}`} value={record.id}>
                {record.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-span-2 ">
          <label className="text-sm">
            <textarea
              value={userInput}
              onChange={e => setUserInput(e.target.value)}
              rows="4"
              className="w-80 rounded-lg border border-gray-300 p-1 text-xs"
              required
            />
          </label>
        </div>

        <div className="space-x-1">
          <label className="text-sm">
            <input
              value={quantity}
              onChange={e => setQuantity(e.target.value)}
              type="number"
              name="quantity"
              className="w-16 rounded-lg border border-gray-300 p-1 text-xs"
              required
            />
          </label>
          <select
            name="value"
            id="value-select"
            value={selectedValue}
            onChange={e => setSelectedValue(e.target.value)}
            className="w-12 rounded-lg border border-gray-300 p-1 text-xs"
          >
            {values.map(record => (
              <option key={`value-${record.id}`} value={record.id}>
                {record.name}
              </option>
            ))}
          </select>
        </div>

        <label className="text-sm">
          <input
            value={price}
            onChange={e => setPrice(e.target.value)}
            type="number"
            name="price"
            className="mr-2 w-24 rounded-lg border border-gray-300 p-1 text-xs"
            required
          />
          <span className="ml-2 text-gray-700">руб.</span>
        </label>

        <p className="text-sm">{price * quantity} руб.</p>

        <Button
          onClick={handleSubmit}
          //className="h-10 rounded-lg border border-gray-500 bg-indigo-100 p-2 text-sm hover:bg-indigo-200"
        >
          Добавить позицию
        </Button>
      </div>
    </div>
  )
}

export default ToDoForm
